import {createStore} from 'vuex'
import util from '../common/util'

const _cache_lang = util.getCache("lang");

const store = createStore({
    state: {
        userinfo: false, //用户身份信息
        accounts: false, //用户余额相关信息
        config: false, //站点全局配置
        // 主题
        userTheme: util.getCache('userTheme') ? util.getCache('userTheme') : 'light',
        lang: _cache_lang, // 默认语言
        symbol: false,
        symbol_rate: false,

        contractCurrent: 0, // 储存当前合约货币id
        contractDepth: {}, // 储存合约深度数据
        contractTicker: {1: {}}, // 储存合约标识符
        contractMark: {}, // 储存合约标记价格
        contractTrade: {}, // 储存合约交易信息
        contractAllTicker: [], // 包含所有合约标识符信息

        favorites: [], // 储存自选币类
        contractSocket: false,

        tradeTicker: {},
        tradeDepth: {},
        tradeTrade: {},
        tradeCurrent: 0,
    },
    getters: {
        lang: state => state.lang,
        symbol: state => {
            if (!state.symbol) {
                const local = JSON.parse(util.getCache('pricing-unit'));
                state.symbol = local.symbol;
            }
            return state.symbol;
        },
        symbol_rate: state => {
            if (!state.symbol_rate) {
                state.symbol_rate = parseFloat(util.getCache('local-fee'));
            }
            return state.symbol_rate;
        },
        isLogin: state => {
            return state.userinfo !== false;
        },
        userAccount: state => {
            return state.userinfo.phone != null ? state.userinfo.phone : state.userinfo.email;
        },
        userNickname: state => {
            if (state.userinfo.nickname != null && state.userinfo.nickname !== '') {
                return state.userinfo.nickname;
            }
            return state.userinfo.phone != null && state.userinfo.phone !== '' ? state.userinfo.phone : state
                .userinfo.email;
        },
        userId: state => {
            if (state.userinfo !== false && state.userinfo.id) {
                return state.userinfo.id;
            }
            return false;
        },
        userPhone: state => {
            return state.userinfo.phone != null ? state.userinfo.phone : false;
        },
        userEmail: state => {
            return state.userinfo.email != null ? state.userinfo.email : false;
        },
        googleCode: state => {
            if (typeof state.userinfo.google2fa == 'undefined') {
                return false;
            }
            return state.userinfo.google2fa != null ? state.userinfo.google2fa : false;
        },
        balanceNull: state => {
            return {
                legal_balance: "0.00",
                balance: "0.00",
                lock_balance: "0.00",
                trade_balance: "0.00",
                lock_trade: "0.00",
                contract_balance: "0.00",
                lock_contract: "0.00",
                future_balance: "0.00",
                lock_future: "0.00",
                finance_balance: "0.00",
                lock_finance: "0.00"
            }
        },
        // 主题
        userTheme: state => {
            return state.userTheme
        },
        //  自选交易对
        favorites: state => state.favorites,
        isFavorite: state => symbol => {
            return state.favorites.includes(symbol)
        },
        contractSocket: state => state.contractSocket,

        tradeTicker: state => state.tradeTicker,
        tradeDepth: state => state.tradeDepth,
        tradeTrade: state => state.tradeTrade,
        userInfo: state => state.userinfo
    },
    mutations: {
        updateLanguage(state, data) {
            state.lang = data;
        },
        userLogin(state, _userinfo) {
            if (_userinfo) {
                state.userinfo = _userinfo;
                if (_userinfo['token']) {
                    util.setCache('token', _userinfo['token']);
                }
            } else {
                state.userinfo = false;
                util.delCache('token');
            }
        },
        updateInfo(state, field, value) {
            state['userinfo'][field] = value;
        },
        setConfig(state, data) {
            state.config = data;
        },
        updateTheme(state, data) {
            state.userTheme = data;
            util.setCache('userTheme', data)
            util.setCache('theme', data)
            if (state.userTheme) {
                document.documentElement.setAttribute('data-theme', data)
            }
        },
        /**
         * 更新本地计价货币符号
         * @param state
         * @param value
         */
        updateSymbol(state, value) {
            state.symbol = value;
        },
        /**
         * 更新本地计价货币汇率
         * @param state
         * @param value
         */
        updateSRate(state, value) {
            state.symbol_rate = value;
        },
        /**
         * 设置当前的显示币种
         * */
        updateContractCurrent(state, currency_id) {
            state.contractCurrent = currency_id;
        },
        /**
         * 更新合约数据
         * */
        updateContractData(state, {type, message}) {
            if (type === 200) {
                state.contractTicker = message;
            }
            if (type === 201 && state.contractCurrent === message.data.currency_id) {
                state.contractDepth = message;
            }
            if (type === 202 && state.contractCurrent === message.data.currency_id) {
                state.contractMark = message;
            }
            if (type === 203 && state.contractCurrent === message.data.currency_id) {
                state.contractTrade = message;
            }
            if (type === 204 || type === 205) {
                uni.$emit('contractOrder', message);
            }
            if (type === 2002) {
                state.contractAllTicker = {type: 2002, data: message};
            }
        },
        updateContractSocket(state, value) {
            state.contractSocket = value;
        },
        updateTradeData(state, data) {
            if (data.type === 100) {
                state.tradeTicker = data;
            }
            if (data.type === 101) {
                state.tradeDepth = data;
            }
            if (data.type === 102) {
                state.tradeTrade = data;
            }
        },
    },
    actions: {
        updateContractData({commit}, data) {
            commit('updateContractData', data);
        },
        updateContractSocket({commit}, data) {
            commit("updateContractSocket", data)
        },
        updateTheme({commit}, data) {
            commit('updateTheme', data);
        }
    }
});

export default store